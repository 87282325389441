import { useTranslation } from "@ahlsell-group/app-localization";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Alert } from "@ahlsell-group/ui-kit/feedback";
import { LinkButton } from "@ahlsell-group/ui-kit/navigation";
import React, { createContext, useContext } from "react";
import { useDispatch } from "react-redux";

import { itemLocationRequired } from "../inventoryLocationSlice";
import useInventoryLocationAlert from "../useInventoryLocationAlert";
import useItemLocation from "../useItemLocation";

export const ItemLocationContext = createContext<string>("");

export const ItemLocationSlot: React.FC = function () {
  const itemLocation = useContext(ItemLocationContext);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{itemLocation}</>;
};

export interface ItemLocationProps {
  warehouseId: number;
  itemId: string;
  includePendingChange?: boolean;

  /** @deprecated Use `successTemplate` instead. */
  includeCurrentPrefix?: boolean;

  /**
   * Template to use for rendering success. Use `<ItemLocationSlot />` to
   * display the item location.
   */
  successTemplate?: JSX.Element;
}

/**
 * Component that will display item location, fetching it if needed.
 */
const ItemLocation: React.FC<ItemLocationProps> = function ItemLocation({
  warehouseId,
  itemId,
  includePendingChange = false,
  includeCurrentPrefix = false,
  successTemplate,
}) {
  const dispatch = useDispatch();
  const itemLocation = useItemLocation(warehouseId, itemId);
  const { t } = useTranslation("common");
  const alertProps = useInventoryLocationAlert(itemId, itemLocation);

  if (!itemLocation) return null;

  if (itemLocation.state === "error") {
    if (itemLocation.reason === "not-found") {
      return <>{t("inventoryLocation.itemLocationNotFound")}</>;
    }
    return (
      <>
        {t("inventoryLocation.itemLocationError")}{" "}
        <LinkButton
          onClick={() =>
            dispatch(itemLocationRequired({ warehouseId, itemId }))
          }
        >
          {t("tryAgain")}
        </LinkButton>
      </>
    );
  }

  if (itemLocation.state === "loading") {
    return <>{t("inventoryLocation.loadingLocation")}</>;
  }

  const success = successTemplate ? (
    <ItemLocationContext.Provider
      value={`${itemLocation.locationId} (${itemLocation.pickStationId})`}
    >
      {successTemplate}
    </ItemLocationContext.Provider>
  ) : (
    <div data-cy="item-location">
      {t(
        includeCurrentPrefix
          ? "inventoryLocation.currentInventoryLocationColon"
          : "inventoryLocation.inventoryLocationColon",
        {
          pickStationId: itemLocation.pickStationId,
          locationId: itemLocation.locationId,
        }
      )}
    </div>
  );

  return (
    <>
      {success}
      {alertProps &&
        (includePendingChange || alertProps.type !== "pending-change-info") && (
          <Alert
            data-cy={alertProps["data-cy"]}
            className="basis-full mt-1"
            severity={alertProps.severity}
            icon={alertProps.icon}
            onDismiss={alertProps.onDismiss}
          >
            <Typography variant="body-sm">{alertProps.message}</Typography>
          </Alert>
        )}
    </>
  );
};

export default ItemLocation;

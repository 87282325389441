import { useTranslation } from "@ahlsell-group/app-localization";
import { StockDeliveryIcon } from "@ahlsell-group/ui-kit-imagery-react/twoToneIcons";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { LinkButton } from "@ahlsell-group/ui-kit/navigation";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import isTruthy from "../../../util/isTruthy";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import { selectStockReplenishmentOrderLines } from "../stockReplenishmentSelectors";
import { stockReplenishmentOrderLinesRequired } from "../stockReplenishmentSlice";
import { getDateFromUnixMilliseconds } from "../util/date";

export interface StockReplenishmentStatusProps {
  itemId: string;
  unit: string;
  className?: string;
}

export const StockReplenishmentStatus: React.FC<StockReplenishmentStatusProps> =
  function ({ itemId, unit, className }) {
    const { t } = useTranslation("common");

    const dispatch = useDispatch();
    const stockId = useSelector(selectWarehouseId);
    const orderLinesState = useSelector(
      selectStockReplenishmentOrderLines({ stockId, itemId })
    );

    useEffect(() => {
      const twentyMinutesAgo = new Date(Date.now() - 20 * 60 * 1000);
      if (
        !orderLinesState ||
        (orderLinesState.state === "idle" &&
          new Date(orderLinesState.loadedAt) < twentyMinutesAgo)
      ) {
        dispatch(stockReplenishmentOrderLinesRequired({ stockId, itemId }));
      }
    }, [orderLinesState, dispatch, stockId, itemId]);

    if (orderLinesState?.state === "error") {
      return (
        <div className={classNames("flex gap-2", className)}>
          <StockDeliveryIcon
            size="small"
            className="mt-2 text-theme-secondary-gray-700 fill-theme-secondary-bg-200"
          />
          <Typography variant="body" data-cy="StockReplenishmentStatus-error">
            {t("stockReplenishment.loadError")}{" "}
            <LinkButton
              onClick={() =>
                dispatch(
                  stockReplenishmentOrderLinesRequired({ stockId, itemId })
                )
              }
            >
              {t("tryAgain")}
            </LinkButton>
          </Typography>
        </div>
      );
    }

    if (!orderLinesState?.data?.length) {
      return null;
    }

    return (
      <div className={classNames("flex flex-col gap-2", className)}>
        {orderLinesState.data.map(
          ({
            orderNumber,
            lineStatus,
            quantityOrdered,
            quantityIncoming,
            acknowledgedDeliveryDate,
          }) => {
            const deliveryDate = acknowledgedDeliveryDate
              ? getDateFromUnixMilliseconds(acknowledgedDeliveryDate)
              : undefined;

            const texts = [
              t(`stockReplenishmentStatus.${lineStatus}` as any, {
                defaultValue: false,
              }) as string | false,

              t("stockReplenishment.status", { lineStatus }),

              t("stockReplenishment.ordered", { unit, quantityOrdered }),

              quantityOrdered !== quantityIncoming &&
                t("stockReplenishment.incoming", { unit, quantityIncoming }),

              t("stockReplenishment.order", { orderNumber }),
            ];

            return (
              <div className="flex gap-2" key={orderNumber}>
                <StockDeliveryIcon
                  size="small"
                  className="mt-1 text-theme-status-warning fill-theme-status-fill-warning"
                />
                <Typography
                  className="basis-0 grow"
                  variant="body"
                  data-cy={`StockReplenishmentStatus-${orderNumber}`}
                >
                  {texts.filter(isTruthy).join(" ")}
                  {deliveryDate && (
                    <div
                      data-cy={`StockReplenishmentStatus-${orderNumber}-deliveryDate`}
                    >
                      {t("stockReplenishment.deliveryDate", { deliveryDate })}
                    </div>
                  )}
                </Typography>
              </div>
            );
          }
        )}
      </div>
    );

    return null;
  };

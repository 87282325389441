import React, { useCallback } from "react";

import ItemSearch from "../../item-search/components/ItemSearch";
import View from "../../routing/components/View";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import WarehouseModal from "../../warehouse/components/WarehouseModal";

import Home from "./Home";
import HomeScan from "./HomeScan";

const HomeRoot = function () {
  const navigate = useNavigate();
  const handleSearchSelect = useCallback(
    async (itemId: string) => {
      navigate(routes.itemInformation.itemIdOrBarcode, {
        itemIdOrBarcode: itemId,
      });
    },
    [navigate]
  );

  return (
    <>
      <View
        component={Home}
        route={[routes.home, routes.home.warehouse]}
        exact
      />
      <View component={HomeScan} route={routes.home} />
      <View
        route={routes.home.warehouse}
        component={WarehouseModal}
        renderMode="always"
      />
      <ItemSearch
        routes={routes.home}
        onSelect={handleSearchSelect}
        onNavigateToScanner={() => navigate(routes.home.scan)}
      />
    </>
  );
};

export default HomeRoot;

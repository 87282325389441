import * as SDCBarcode from "scandit-capacitor-datacapture-barcode";
import * as SDCCore from "scandit-capacitor-datacapture-core";

import ScanningContextService, {
  ErrorScanningContextState,
  InitializingScanningContextState,
  ReadyScanningContextState,
  ScanningContextServiceBase,
  UninitializedScanningContextState,
} from "./ScanningContextService";
import { CameraError } from "./types";

interface InternalCapacitorContext {
  view: SDCCore.DataCaptureView;
  camera: SDCCore.Camera;
  barcodeCapture: SDCBarcode.BarcodeCapture;
  overlay: SDCBarcode.BarcodeCaptureOverlay;
}

type InternalCapacitorScanningContextState =
  | UninitializedScanningContextState
  | InitializingScanningContextState
  | (ReadyScanningContextState & InternalCapacitorContext)
  | ErrorScanningContextState;

const getDefaultBarcodeSettings = () => {
  const settings = new SDCBarcode.BarcodeCaptureSettings();
  settings.enableSymbologies([
    SDCBarcode.Symbology.EAN8,
    SDCBarcode.Symbology.EAN13UPCA,
    SDCBarcode.Symbology.UPCE,
    SDCBarcode.Symbology.Code39,
    SDCBarcode.Symbology.Code128,
    SDCBarcode.Symbology.QR,
  ]);
  settings.codeDuplicateFilter = 2000;
  return settings;
};

export default class CapacitorScanningService
  extends ScanningContextServiceBase
  implements ScanningContextService
{
  private targetElement?: HTMLElement;

  constructor(private readonly licenseKey: string) {
    super();
  }

  private internalState: InternalCapacitorScanningContextState = {
    state: "uninitialized",
  };

  get state(): InternalCapacitorScanningContextState {
    return this.internalState;
  }

  set state(value) {
    const previousState = this.internalState;
    this.internalState = value;
    this.triggerStateChange(value, previousState);
  }

  get cameraError(): CameraError | undefined {
    if (this.state.state === "error") return this.state.error;
    return undefined;
  }

  private readonly listener: SDCBarcode.BarcodeCaptureListener = {
    didScan: (
      _: SDCBarcode.BarcodeCapture,
      session: SDCBarcode.BarcodeCaptureSession
    ) => {
      this.triggerScan(session.newlyRecognizedBarcodes);
    },
  };

  async initialize(): Promise<void> {
    this.state = {
      state: "initializing",
    };
    await SDCCore.ScanditCaptureCorePlugin.initializePlugins();
    const captureContext = SDCCore.DataCaptureContext.forLicenseKey(
      this.licenseKey
    );
    const view = new SDCCore.DataCaptureView();
    view.context = captureContext;
    const camera = SDCCore.Camera.default;
    if (!camera) {
      this.state = { state: "error", error: "NoCameraAvailableError" };
      return;
    }
    await camera.applySettings(
      SDCBarcode.BarcodeCapture.recommendedCameraSettings
    );

    await captureContext.setFrameSource(camera);

    const settings = getDefaultBarcodeSettings();
    settings.locationSelection = new SDCCore.RadiusLocationSelection(
      new SDCCore.NumberWithUnit(5.0, SDCCore.MeasureUnit.Pixel)
    );
    const barcodeCapture = SDCBarcode.BarcodeCapture.forContext(
      captureContext,
      getDefaultBarcodeSettings()
    );
    barcodeCapture.isEnabled = false;
    barcodeCapture.addListener(this.listener);
    const overlay =
      SDCBarcode.BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
        barcodeCapture,
        view,
        SDCBarcode.BarcodeCaptureOverlayStyle.Frame
      );
    const viewfinder = new SDCCore.LaserlineViewfinder(
      SDCCore.LaserlineViewfinderStyle.Animated
    );
    view.pointOfInterest = new SDCCore.PointWithUnit(
      new SDCCore.NumberWithUnit(0.5, SDCCore.MeasureUnit.Fraction),
      new SDCCore.NumberWithUnit(0.5, SDCCore.MeasureUnit.Fraction)
    );
    view.scanAreaMargins = new SDCCore.MarginsWithUnit(
      new SDCCore.NumberWithUnit(0.25, SDCCore.MeasureUnit.Fraction),
      new SDCCore.NumberWithUnit(0.25, SDCCore.MeasureUnit.Fraction),
      new SDCCore.NumberWithUnit(0.33, SDCCore.MeasureUnit.Fraction),
      new SDCCore.NumberWithUnit(0.33, SDCCore.MeasureUnit.Fraction)
    );
    overlay.viewfinder = viewfinder;

    if (this.targetElement) {
      view.connectToElement(this.targetElement);
      this.targetElement = undefined;
    }

    this.state = {
      state: "ready",
      view,
      camera,
      barcodeCapture,
      overlay,
    };
  }

  connectElement(element: HTMLElement): void {
    if (
      this.state.state === "uninitialized" ||
      this.state.state === "initializing"
    ) {
      this.targetElement = element;
      return;
    }
    if (this.state.state !== "error") {
      this.state.view.connectToElement(element);
    }
  }

  disconnectElement(): void {
    if (
      this.state.state === "uninitialized" ||
      this.state.state === "initializing"
    ) {
      this.targetElement = undefined;
      return;
    }
    if (this.state.state !== "error") {
      this.state.view.detachFromElement();
    }
  }

  async startCamera(): Promise<void> {
    if (this.state.state === "camera-active" || this.state.state === "error") {
      return;
    }
    if (this.state.state !== "ready") {
      throw new Error(
        "Scanning context not ready yet. Wait for state to change to ready (startCamera)"
      );
    }
    try {
      await this.state.camera.switchToDesiredState(SDCCore.FrameSourceState.On);
      this.state.state = "camera-active";
    } catch (e: any) {
      this.state = {
        state: "error",
        error: e.name ?? "UnknownError",
      };
    }
  }

  startScanning(): Promise<void> {
    if (this.state.state !== "camera-active") {
      throw new Error(
        "Scanning context not ready yet. State must first be initialized, and the camera must then be started (startScanning)"
      );
    }
    if (!this.state.barcodeCapture.isEnabled) {
      this.state.barcodeCapture.isEnabled = true;
    }

    return Promise.resolve();
  }

  stopScanning(): Promise<void> {
    if (
      (this.state.state !== "camera-active" && this.state.state !== "ready") ||
      !this.state.barcodeCapture.isEnabled
    ) {
      return Promise.resolve();
    }

    this.state.barcodeCapture.isEnabled = false;
    return Promise.resolve();
  }

  async stopCamera(): Promise<void> {
    if (this.state.state !== "camera-active" && this.state.state !== "ready") {
      return;
    }

    await this.stopScanning();
    await this.state.camera.switchToDesiredState(SDCCore.FrameSourceState.Off);
    this.state.state = "ready";
  }
}

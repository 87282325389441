import { autonyms, LocalizationService } from "@ahlsell-group/app-localization";
import {
  AxiosItemSearchService,
  AxiosItemService,
  AxiosProductDataHubService,
  Item,
  ItemSearchResultItem,
  ItemSearchService,
  ItemService,
  Language,
  PurchaseHoldInfo,
  SearchOptions,
} from "@ahlsell-group/store20-product-service";

import { selectHasFeatureFlag } from "../../features/config/configSelectors";
import StoreAccessor from "../redux/StoreAccessor";

type LanguageMap = {
  [key: keyof typeof autonyms]: Language;
};

export default class ItemServiceProxy
  implements ItemService, ItemSearchService
{
  constructor(
    private readonly storeAccessor: StoreAccessor,
    private readonly localizationService: LocalizationService,
    private readonly itemService: AxiosItemService,
    private readonly itemSearchService: AxiosItemSearchService,
    private readonly axiosProductDataHubService: AxiosProductDataHubService
  ) {}

  getItem(itemId: string, webshopOnly?: boolean): Promise<Item> {
    // When PDH has support for technical specifications, this should be updated with the conditional to use that.
    return this.itemService.getItem(itemId, webshopOnly);
  }

  getItems(itemIds: string[], webshopOnly?: boolean): Promise<Item[]> {
    // When PDH has support for technical specifications, this should be updated with the conditional to use that.
    return this.itemService.getItems(itemIds, webshopOnly);
  }

  getPurchaseHold(
    itemId: string,
    warehouseId: number
  ): Promise<PurchaseHoldInfo> {
    // When PDH has support for purchase hold on a stock level, this should be updated with the conditional to use that.
    return this.itemService.getPurchaseHold(itemId, warehouseId);
  }

  search(
    searchQuery: string,
    { top, skip, ...searchOptions }: SearchOptions = {}
  ): Promise<ItemSearchResultItem[]> {
    if (this.useProductDataHubService()) {
      return this.axiosProductDataHubService.search(
        searchQuery,
        this.getLanguage(),
        { top, skip }
      );
    }
    return this.itemSearchService.search(searchQuery, searchOptions);
  }

  suggestByItemId(searchQuery: string): Promise<ItemSearchResultItem[]> {
    if (this.useProductDataHubService()) {
      return this.axiosProductDataHubService.suggestByItemId(
        searchQuery,
        this.getLanguage()
      );
    }
    return this.itemSearchService.suggestByItemId(searchQuery);
  }

  async getItemIdByBarcode(
    barcode: string,
    webshopOnly?: boolean
  ): Promise<string | undefined> {
    // When PDH has a simpler structure for GTIN barcodes, this should be updated with the conditional to use that.
    return this.itemSearchService.getItemIdByBarcode(barcode, webshopOnly);
  }

  private useProductDataHubService() {
    return selectHasFeatureFlag(
      this.storeAccessor.getStore().getState(),
      "ProductDataHub"
    );
  }

  private getLanguage(): Language {
    const languageMap: LanguageMap = {
      en: "eng",
      nb: "nor",
      fi: "fin",
      sv: "swe",
    };

    const language = this.localizationService.getCurrentLanguage();
    return languageMap[language];
  }
}

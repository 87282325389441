import { useTranslation } from "@ahlsell-group/app-localization";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import {
  ArrowLeftIcon,
  ChevronRightIcon,
  StockDeliveryIcon,
  StockInStoreIcon,
} from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { IconButton } from "@ahlsell-group/ui-kit/inputs";
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
} from "@ahlsell-group/ui-kit/surfaces";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectLegalEntityId,
  selectUserEmail,
} from "../../../app/appSelectors";
import { selectFeatureFlags } from "../../config/configSelectors";
import ErrorModal from "../../error/components/ErrorModal";
import ItemLocation from "../../inventory-location/components/ItemLocation";
import useItemImageUrl from "../../item-image/useItemImageUrl";
import { getCentralWarehousesForLegalEntity } from "../../item-stock/centralWarehousesForLegalEntity";
import ItemStock from "../../item-stock/components/ItemStock";
import { AppLoadingPage } from "../../page/components/AppLoadingPage";
import { AppPage } from "../../page/components/AppPage";
import RouteLink from "../../routing/components/RouteLink";
import { ViewComponentProps } from "../../routing/components/View";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import { StockReplenishmentStatus } from "../../stock-replenishment/components/StockReplenishmentStatus";
import {
  selectWarehouseCoordinates,
  selectWarehouseId,
  selectWarehouseName,
} from "../../warehouse/warehouseSelectors";
import { selectItemState } from "../itemInformationSelectors";
import { itemRequired } from "../itemInformationSlice";

import ItemHtmlField from "./ItemHtmlField";

type ItemInfoRouteParams = {
  itemIdOrBarcode: string;
  barcode?: boolean;
};

const ItemInformation: React.FC<ViewComponentProps<ItemInfoRouteParams>> =
  function ({ params }) {
    const { itemIdOrBarcode, barcode } = params;
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const itemState = useSelector(selectItemState);
    const currentWarehouse = useSelector(selectWarehouseId);
    const currentWarehouseName = useSelector(selectWarehouseName);
    const warehouseCoordinates = useSelector(selectWarehouseCoordinates);
    const userEmail = useSelector(selectUserEmail);
    const legalEntityId = useSelector(selectLegalEntityId);
    const assetUrl = useItemImageUrl();
    const centralWarehousesCount = legalEntityId
      ? getCentralWarehousesForLegalEntity(legalEntityId).length
      : 0;
    const featureFlags = useSelector(selectFeatureFlags);
    const userHasStockReplenishmentStatusFeatureFlag = featureFlags.includes(
      "StockReplenishmentStatus"
    );

    useEffect(() => {
      if (itemState.state === "idle") {
        dispatch(
          itemRequired(
            barcode === true
              ? { barcode: itemIdOrBarcode }
              : { itemId: itemIdOrBarcode }
          )
        );
      }
    }, [itemState, itemIdOrBarcode, barcode, dispatch]);

    if (itemState.state === "error") {
      return (
        <ErrorModal
          data-cy="item-information-error"
          data-cy-error-reason={itemState.error.reason}
          category="itemInformation"
          error={itemState.error}
          onClose={() => navigate.back()}
        />
      );
    }

    if (itemState.state === "loading") {
      return (
        <AppLoadingPage onCancel={() => navigate.back()}>
          {t("itemInformation.loadingItem")}
        </AppLoadingPage>
      );
    }

    if (itemState.state === "item-open") {
      const item = itemState.currentItem;

      return (
        <AppPage backgroundColor="white">
          <PageSection className="border-b" data-cy="item-information">
            <IconButton
              className="absolute"
              variant="tertiary"
              rounded="full"
              size="large"
              icon={ArrowLeftIcon}
              data-cy="ItemInformation-back"
              onClick={() => navigate.back()}
            />
            <div className="mx-auto py-9 max-w-[11.25rem] max-h-[11.25rem]">
              <img
                className="object-contain w-full h-full"
                src={assetUrl(item.assetUrl)}
                alt=""
              />
            </div>

            <div className="pt-4">
              <Typography variant="heading-h3" color="gray">
                {item.brand}
              </Typography>
              <Typography className="mt-3" variant="heading-h2">
                {item.name}
              </Typography>
              <Typography component="p" variant="body-detail" className="mt-2">
                {item.partDescription1} {item.partDescription2}
              </Typography>
              <Typography
                variant="heading-h4"
                component="div"
                className="flex flex-wrap items-center mt-3"
              >
                <ItemLocation
                  warehouseId={currentWarehouse}
                  itemId={item.id}
                  includePendingChange
                />
              </Typography>
              <Typography component="p" className="mt-4">
                <Typography component="span" color="gray">
                  {t("itemInformation.itemId")}
                </Typography>{" "}
                {item.id}
              </Typography>
              <Typography component="p" className="mt-2">
                <Typography component="span" color="gray">
                  {t("itemInformation.vendorItemId")}
                </Typography>{" "}
                {item.vendorId ?? t("itemInformation.missing")}
              </Typography>
              <div className="mt-2">
                <ItemStock
                  warehouseId={currentWarehouse}
                  warehouseName={currentWarehouseName}
                  itemId={item.id}
                  size="small"
                />
              </div>
              {userHasStockReplenishmentStatusFeatureFlag && (
                <StockReplenishmentStatus
                  itemId={itemIdOrBarcode}
                  unit={item.unit}
                  className="mt-4"
                />
              )}
              {warehouseCoordinates &&
                // This feature is disabled for AWW employees due to a limitation in getting reliable store data.
                // The stores index does not support having two stores with the same storeId, but with different contexts.
                // On top of that, the mapping between Vivaldi and the webshops (happens in APIM /stores) is fragile at best.
                // This shouldn't be revisited until Ahlsell has a unified way of storing store data.
                !userEmail?.endsWith("@ahlsellworkwear.se") && (
                  <RouteLink
                    route={routes.itemInformation.otherWarehousesItemStock}
                    routeParams={{ itemId: item.id }}
                    variant="boxed"
                    size="small"
                    icon={ChevronRightIcon}
                    className="mt-4"
                    data-cy="item-information-other-warehouses-item-stock"
                  >
                    <Typography variant="body-sm" className="mb-2">
                      <strong>{t("itemStock.stockStatus")}</strong>
                    </Typography>

                    <div className="mb-2">
                      <StockDeliveryIcon className="inline-block mr-2" />
                      {t("itemStock.seeNearbyStores")}
                    </div>
                    <div>
                      <StockInStoreIcon className="inline-block mr-2" />
                      {t("itemStock.centralWarehouses", {
                        count: centralWarehousesCount,
                      })}
                    </div>
                  </RouteLink>
                )}
            </div>
          </PageSection>
          <PageSection backgroundColor="white" padding="none">
            <AccordionGroup scrollOnExpand maxOneExpanded>
              {item.technicalSpecifications && (
                <Accordion>
                  <AccordionSummary>
                    {t("itemInformation.technicalSpecification")}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body" className="mx-3.5">
                      <ul
                        className="list-disc"
                        data-cy="ItemInformation-technicalSpecifications"
                      >
                        {item.technicalSpecifications.map(({ name, value }) => (
                          <li key={name}>
                            <ItemHtmlField>{`${name}: ${value}`}</ItemHtmlField>
                          </li>
                        ))}
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )}
              {item.description && (
                <Accordion>
                  <AccordionSummary>
                    {t("itemInformation.itemDescription")}
                  </AccordionSummary>
                  <AccordionDetails data-cy="ItemInformation-description">
                    <ItemHtmlField>{item.description}</ItemHtmlField>
                  </AccordionDetails>
                </Accordion>
              )}
            </AccordionGroup>
          </PageSection>
        </AppPage>
      );
    }

    return null;
  };

export default ItemInformation;

import { useTranslation } from "@ahlsell-group/app-localization";
import {
  Modal,
  ModalAction,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@ahlsell-group/ui-kit/feedback";
import React from "react";

import { StockTakeCostDiff } from "./StockTakeCostDiff";

interface ManualStockTakingItemDiffModalProps {
  open: boolean;
  stockQuantity: number;
  stockTakingQuantity: number;
  weightedAverageUnitCost: number;
  currency: string;
  unit: string;
  onCancel: () => void;
  onCorrect: () => void;
  onContinue: () => void;
}

const ManualStockTakingItemDiffModal: React.FC<ManualStockTakingItemDiffModalProps> =
  function ({
    open,
    stockQuantity,
    stockTakingQuantity,
    weightedAverageUnitCost,
    currency,
    unit,
    onCancel,
    onCorrect,
    onContinue,
  }) {
    const { t } = useTranslation("common");

    return (
      <Modal
        open={open}
        onCancel={onCancel}
        variant="card"
        overrideHtmlMetaThemeColor="backdrop-on-primary-surface-light"
      >
        <ModalHeader data-cy="ManualStockTakingItemDiffModal">
          {t("stockTaking.quantityDiffers")}
        </ModalHeader>
        <ModalContent className="text-left">
          <div className="flex justify-between">
            <div>{t("stockTaking.stockTakingQuantity")} </div>
            <div>
              <strong>
                <span data-cy="ManualStockTakingItemDiffModal-stockTakingQuantity">
                  {stockTakingQuantity}
                </span>{" "}
                {unit}
              </strong>
            </div>
          </div>
          <div className="pb-4 flex justify-between">
            <div>{t("stockTaking.stockQuantity")} </div>
            <div>
              <strong>
                <span data-cy="ManualStockTakingItemDiffModal-stockQuantity">
                  {stockQuantity}
                </span>{" "}
                {unit}
              </strong>
            </div>
          </div>
          <div
            className="pb-4 text-theme-secondary-gray-700 flex justify-between"
            data-cy="ManualStockTakingItemDiffModal-diff"
          >
            <div>
              <strong>
                {t("stockTaking.diff")}
                {": "}
              </strong>
            </div>
            <div>
              <strong>
                <StockTakeCostDiff
                  stockQuantity={stockQuantity}
                  stockTakingQuantity={stockTakingQuantity}
                  weightedAverageUnitCost={weightedAverageUnitCost}
                  currency={currency}
                  diff
                  data-cy="ManualStockTakingItemDiffModal-totalWeightedCostDiff"
                />
              </strong>
            </div>
          </div>
          <div>{t("stockTaking.quantityDiffersDescription")}</div>
        </ModalContent>
        <ModalActions orientation="vertical">
          <ModalAction
            mode="button"
            variant="secondary"
            onClick={onCorrect}
            data-cy="ManualStockTakingItemDiffModal-correct"
          >
            {t("stockTaking.correct")}
          </ModalAction>
          <ModalAction
            mode="link-button"
            onClick={onContinue}
            data-cy="ManualStockTakingItemDiffModal-ok"
          >
            {t("save")}
          </ModalAction>
        </ModalActions>
      </Modal>
    );
  };

export default ManualStockTakingItemDiffModal;

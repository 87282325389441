import { useTranslation } from "@ahlsell-group/app-localization";
import {
  CircleExclamationIcon,
  IconProps,
  InfoIcon,
} from "@ahlsell-group/ui-kit-imagery-react";
import { AlertProps } from "@ahlsell-group/ui-kit/feedback";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectIsPendingChangeAlertDismissed } from "./inventoryLocationSelectors";
import {
  dismissPendingChangeAlert,
  ItemLocationWithState,
} from "./inventoryLocationSlice";

interface InventoryLocationAlertProps {
  type: "pending-change-info" | "location-change-failed";
  severity: AlertProps["severity"];
  icon: (props: IconProps) => JSX.Element;
  onDismiss: () => void;
  message: string;
  "data-cy": string;
}

export default (
  itemId: string,
  itemLocationWithState?: ItemLocationWithState
): InventoryLocationAlertProps | undefined => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const pendingChangeAlertDismissed = useSelector(
    selectIsPendingChangeAlertDismissed(itemId)
  );
  const [dismissedChangeError, setDismissedChangeError] = useState(false);

  if (dismissedChangeError || itemLocationWithState?.state !== "idle") {
    return undefined;
  }

  function getLocationChangeErrorDetails(
    errorCode:
      | "Unknown"
      | "MovementOnCustomerOrder"
      | "MovementOnInventoryAudit"
  ) {
    switch (errorCode) {
      case "MovementOnCustomerOrder":
        return t("inventoryLocation.movementOnCustomerOrder");
      case "MovementOnInventoryAudit":
        return t("inventoryLocation.movementOnInventoryAudit");
      case "Unknown":
        return undefined;
      default:
        return undefined;
    }
  }

  if (itemLocationWithState?.changeError) {
    const { latestErrorCode, fromLocationId, fromPickStationId } =
      itemLocationWithState.changeError;
    const details = getLocationChangeErrorDetails(latestErrorCode);

    return {
      type: "location-change-failed",
      severity: "error",
      icon: CircleExclamationIcon,
      onDismiss: () => setDismissedChangeError(true),
      message: `${t("inventoryLocation.locationChangeFailed", {
        fromLocationId,
        fromPickStationId,
        toLocationId: itemLocationWithState.locationId,
        toPickStationId: itemLocationWithState.pickStationId,
      })}${details ? ` ${details}` : ""} ${t(
        "inventoryLocation.verifyInVivaldi"
      )}`,
      "data-cy": "ItemLocation-changeErrorAlert",
    };
  }

  if (
    !itemLocationWithState.isUpdatedInVivaldi &&
    !pendingChangeAlertDismissed
  ) {
    return {
      type: "pending-change-info",
      severity: "info",
      icon: InfoIcon,
      onDismiss: () => dispatch(dismissPendingChangeAlert(itemId)),
      message: t("inventoryLocation.locationChangeIsPending"),
      "data-cy": "ItemLocationPendingChangeAlert",
    };
  }

  return undefined;
};

import { useTranslation } from "@ahlsell-group/app-localization";
import { ValidatedItem } from "@ahlsell-group/store20-stock-taking-service";
import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import router from "../../routing/router";
import routes from "../../routing/routes";
import useAutofocus from "../../routing/useAutofocus";
import useNavigate from "../../routing/useNavigate";
import useRoute from "../../routing/useRoute";
import {
  selectItemReviewValidationError,
  selectItems,
  selectManualStockTakingDeleteItemState,
  selectManualStockTakingUpdateItemError,
} from "../manualStockTakingSelectors";
import { updateItem } from "../manualStockTakingSlice";

import {
  ManualStockTakingConfirmBalanceModal,
  ManualStockTakingConfirmBalanceModalResult,
} from "./ManualStockTakingConfirmBalanceModal";
import ManualStockTakingItemDiffModal from "./ManualStockTakingItemDiffModal";
import ManualStockTakingItemForm from "./ManualStockTakingItemForm";
import ManualStockTakingLoadingModal from "./ManualStockTakingLoadingModal";
import { ManualStockTakingUpdateItemErrorModal } from "./ManualStockTakingUpdateItemErrorModal";

interface ManualStockTakingValidItemProps {
  item: ValidatedItem;
}

const ManualStockTakingValidItem: React.FC<ManualStockTakingValidItemProps> =
  function ({ item }) {
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const items = useSelector(selectItems);
    const updateItemError = useSelector(selectManualStockTakingUpdateItemError);
    const itemInList = item.canStockTake
      ? items.find((x) => x.itemId === item.itemId)
      : undefined;

    // Mutable references to updated values. Used to avoid rerendering on each
    // quantity or comment update.
    const updatedQuantity = useRef(itemInList?.stockTakingQuantity ?? -1);
    const updatedComment = useRef(itemInList?.comment);

    const [isFormOnTop] = useRoute(routes.stockTaking.manual.item, {
      exact: true,
    });
    const deleteItemState = useSelector(selectManualStockTakingDeleteItemState);

    const { acceptQuantity, modal, continueTo } = router.getState().params;
    const { autofocus, handleAutofocus } = useAutofocus();
    const [isDiffModalOpen, navigateToDiffModal] = useRoute(
      routes.stockTaking.manual.item.diff
    );

    const save = async (isInDiffModal: boolean = false) => {
      if (isInDiffModal) {
        await navigate.back();
      }
      navigate(routes.stockTaking.manual.item, {
        itemIdOrBarcode: item.itemId,
        modal: "saving",
      });
      dispatch(
        updateItem({
          item: {
            ...item,
            stockTakingQuantity: updatedQuantity.current,
            comment: updatedComment.current,
          },
          continueScanning: continueTo === "scanner",
        })
      );
    };

    const handleSave = () => {
      const diffAccepted =
        updatedQuantity.current === acceptQuantity &&
        Boolean(updatedComment.current);
      const shouldShowDiffModal =
        item.expectedQuantity !== updatedQuantity.current && !diffAccepted;

      if (shouldShowDiffModal) {
        navigateToDiffModal({
          itemIdOrBarcode: item.itemId,
          continueTo,
        });
        return;
      }

      save(false);
    };

    const reviewValidationError = useSelector(
      selectItemReviewValidationError(item.itemId)
    );

    const handleCancel = async () => {
      const needsToConfirmStockBalance =
        reviewValidationError === "ExpectedQuantityDiffers";

      if (!needsToConfirmStockBalance) {
        await navigate.back();
        return;
      }

      navigate(routes.stockTaking.manual.item, {
        itemIdOrBarcode: item.itemId,
        modal: "confirmBalance",
      });
    };

    const handleConfirmBalanceResult = async (
      result: ManualStockTakingConfirmBalanceModalResult
    ) => {
      await navigate.back();

      if (result === "yes") {
        handleSave();
      } else if (result === "no") {
        await navigate.back();
      } else if (result === "cancel") {
        // Do nothing.
      }
    };

    const handleRemove = () => {
      const { itemId } = item;
      navigate(routes.stockTaking.manual.item.remove, {
        itemIdOrBarcode: itemId,
      });
    };

    const hasAcceptQuantity = typeof acceptQuantity === "number";
    const showComment =
      hasAcceptQuantity ||
      (!!itemInList &&
        item.canStockTake &&
        itemInList.stockTakingQuantity !== item.expectedQuantityWhenCounted);

    return (
      <>
        <ManualStockTakingItemForm
          item={item}
          onCancel={handleCancel}
          onSaveAndContinue={handleSave}
          onRemove={handleRemove}
          isNew={itemInList === undefined}
          showDiff={hasAcceptQuantity}
          stockTakingQuantity={
            item.stockTakingQuantity ?? updatedQuantity.current
          }
          showComment={showComment}
          comment={itemInList?.comment}
          lastStockTakeDate={item.previousStockTakes?.[0]?.date}
          autofocusQuantity={isFormOnTop && autofocus}
          onAutofocusQuantity={handleAutofocus}
          onQuantityUpdate={(quantity: number) => {
            updatedQuantity.current = quantity;
          }}
          onCommentUpdate={(comment?: string) => {
            updatedComment.current = comment;
          }}
        />
        <ManualStockTakingItemDiffModal
          open={isDiffModalOpen}
          stockQuantity={item.expectedQuantityWhenCounted}
          stockTakingQuantity={updatedQuantity.current}
          unit={item.unit}
          weightedAverageUnitCost={item.weightedAverageUnitCost}
          currency={item.currency}
          onCancel={() => navigate.back()}
          onCorrect={async () => {
            await navigate.back();
            navigate(
              routes.stockTaking.manual.item,
              {
                itemIdOrBarcode: item.itemId,
                autofocus: null,
                acceptQuantity: updatedQuantity.current,
                continueTo,
              },
              { replace: true }
            );
          }}
          onContinue={() => save(true)}
        />
        {modal === "confirmBalance" && (
          <ManualStockTakingConfirmBalanceModal
            stockTakingQuantity={updatedQuantity.current}
            unit={item.unit}
            onResult={handleConfirmBalanceResult}
          />
        )}
        {modal === "saving" && (
          <ManualStockTakingLoadingModal
            headerText={t("stockTaking.updatingStockTake")}
            detailText={t("stockTaking.updatingItemInfoStockTake")}
            onClose={() => navigate.back()}
          />
        )}
        {deleteItemState === "in-progress" && (
          <ManualStockTakingLoadingModal
            headerText={t("stockTaking.updatingStockTake")}
            detailText={t("stockTaking.deletingItemFromStockTake")}
            onClose={() => navigate.back()}
            overrideHtmlMetaThemeColor="backdrop-on-primary-surface-light"
          />
        )}
        {updateItemError && (
          <ManualStockTakingUpdateItemErrorModal error={updateItemError} />
        )}
      </>
    );
  };

export default ManualStockTakingValidItem;

import React from "react";

import { StockTakeQuantity } from "./StockTakeQuantity";

export interface StockTakeCostDiffProps {
  stockQuantity?: number;
  stockTakingQuantity?: number;
  weightedAverageUnitCost?: number;
  totalWeightedAverageCostDiff?: number;
  currency: string;
  diff?: boolean;
  warn?: boolean;
  "data-cy"?: string;
}

export const StockTakeCostDiff: React.FC<StockTakeCostDiffProps> = function ({
  stockQuantity = 0,
  stockTakingQuantity = 0,
  weightedAverageUnitCost,
  totalWeightedAverageCostDiff,
  currency,
  diff,
  warn,
  "data-cy": dataCy = `StockTakeCostDiff-${stockQuantity}`,
}) {
  if (!weightedAverageUnitCost && !totalWeightedAverageCostDiff) {
    return null;
  }
  const roundAwayFromZero = (num: number) =>
    num > 0 ? Math.ceil(num) : Math.floor(num);
  const stockDiff = stockTakingQuantity - stockQuantity;
  // In the case where weightedAverageUnitCost is not provided, we know we have the total already so default 0 does not matter.
  const calculatedTotalCostDiff = (weightedAverageUnitCost ?? 0) * stockDiff;

  return (
    <StockTakeQuantity
      number={roundAwayFromZero(
        totalWeightedAverageCostDiff || calculatedTotalCostDiff
      )}
      unit={currency}
      warn={warn ?? totalWeightedAverageCostDiff !== 0}
      diff={diff}
      data-cy={dataCy}
    />
  );
};

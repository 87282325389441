import {
  GetPickStationsWithLocationsError,
  PickStationWithLocations,
} from "@ahlsell-group/store20-inventory-location-service";
import { OperationFailedError } from "@ahlsell-group/store20-service-core";
import { call, put, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import {
  selectIsPickStationsWithLocationsLoading,
  selectPickStationsWithLocations,
} from "../inventoryLocationSelectors";
import {
  locationsLoadError,
  locationsLoading,
  locationsReceived,
} from "../inventoryLocationSlice";

export default function* loadLocationsSaga({
  handleSagaError,
  inventoryLocationService,
  logService,
}: ServiceContainer): Saga {
  try {
    const pickStationsWithLocations: PickStationWithLocations[] | undefined =
      yield select(selectPickStationsWithLocations);
    const isLocationsLoading: boolean = yield select(
      selectIsPickStationsWithLocationsLoading
    );

    if (pickStationsWithLocations !== undefined || isLocationsLoading) {
      // Only load if needed.
      return;
    }

    yield put(locationsLoading());

    const warehouseId: number = yield select(selectWarehouseId);

    const locations: PickStationWithLocations[] = yield call(
      [inventoryLocationService, "getPickStationsWithLocations"],
      warehouseId
    );

    if (!Array.isArray(locations)) {
      logService.log("warning", "unhandled:saga", {
        error: "getPickStationsWithLocations",
        message: `S20-2450 Locations are not an array ${locations}`,
      });
      throw new OperationFailedError<GetPickStationsWithLocationsError>(
        "unknown"
      );
    }

    yield put(locationsReceived(locations));
  } catch (e) {
    yield call(
      handleSagaError,
      (errorCode) => `saga:inventory-location:loadLocationsSaga:${errorCode}`,
      e,
      put(locationsLoadError(toErrorActionPayload(e)))
    );
  }
}

import { useTranslation } from "@ahlsell-group/app-localization";
import {
  formatComment,
  ValidatedItem,
} from "@ahlsell-group/store20-stock-taking-service";
import {
  ArrowLeftIcon,
  CrossIcon,
  InfoIcon,
  TrashIcon,
} from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Alert } from "@ahlsell-group/ui-kit/feedback";
import {
  Button,
  IconButton,
  NumberInput,
  TextField,
} from "@ahlsell-group/ui-kit/inputs";
import AutoNumeric from "autonumeric";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { selectFeatureFlags } from "../../config/configSelectors";
import ItemLocation from "../../inventory-location/components/ItemLocation";
import ItemImage from "../../item-image/components/ItemImage";
import { AppPage } from "../../page/components/AppPage";
import { PurchaseHold } from "../../purchase-hold/components/PurchaseHold";
import { StockReplenishmentStatus } from "../../stock-replenishment/components/StockReplenishmentStatus";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import squareCubedToUnicode from "../util/squareCubedToUnicode";

import { LastStockTakeDate } from "./LastStockTakeDate";

interface ManualStockTakingItemFormProps {
  item: ValidatedItem;
  onCancel: () => void;
  onSaveAndContinue: () => void;
  onRemove: () => void;
  isNew: boolean;
  showDiff: boolean;
  stockTakingQuantity?: number;
  showComment?: boolean;
  comment?: string;
  lastStockTakeDate?: string;
  /** Whether to focus the quantity field when the component is initially rendered. */
  autofocusQuantity?: boolean;
  onAutofocusQuantity?: () => void;
  onQuantityUpdate: (quantity: number) => void;
  onCommentUpdate: (comment?: string) => void;
}

const ManualStockTakingItemForm: React.FC<ManualStockTakingItemFormProps> =
  function ({
    item,
    isNew,
    showDiff,
    onCancel,
    onSaveAndContinue,
    onRemove,
    stockTakingQuantity,
    showComment = false,
    comment,
    lastStockTakeDate,
    autofocusQuantity,
    onAutofocusQuantity,
    onQuantityUpdate,
    onCommentUpdate,
  }) {
    // The initial value for new items should be `0`, but the type allows
    // `undefined` too, since the user may (temporarily) remove the content of
    // the field.
    const [currentStockTakingQuantity, setCurrentStockTakingQuantityLocal] =
      useState<number | undefined>(stockTakingQuantity ?? 0);
    const [updatedComment, setUpdatedCommentLocal] = useState(comment);
    const formattedUpdatedComment = useMemo(
      () => formatComment(updatedComment),
      [updatedComment]
    );
    const featureFlags = useSelector(selectFeatureFlags);
    const userHasStockReplenishmentStatusFeatureFlag = featureFlags.includes(
      "StockReplenishmentStatus"
    );

    const setCurrentStockTakingQuantity = (value?: number) => {
      setCurrentStockTakingQuantityLocal(value);
      onQuantityUpdate(value ?? 0);
    };
    const setUpdatedComment = (value?: string) => {
      setUpdatedCommentLocal(value);
      onCommentUpdate(value);
    };

    const maxLength = 45;
    const isTooLong = (formattedUpdatedComment?.length ?? 0) > maxLength;
    const canSave = !isTooLong && currentStockTakingQuantity !== undefined;

    const { t, i18n } = useTranslation("common");

    const warehouseId = useSelector(selectWarehouseId);

    const focusQuantityCallback = useCallback(
      (autoNumeric: AutoNumeric | null) => {
        if (autoNumeric && autofocusQuantity) {
          autoNumeric.node().focus();
          autoNumeric.select();
          onAutofocusQuantity?.();
        }
      },
      [autofocusQuantity, onAutofocusQuantity]
    );

    const handleSave = () => () => {
      if (!canSave) return;
      onSaveAndContinue();
    };

    return (
      <AppPage backgroundColor="white">
        <form
          className="absolute inset-0 bg-white overflow-auto p-6 flex flex-col"
          // Use `will-change:z-index` as a workaround for bug on iOS 17.
          // https://bugs.webkit.org/show_bug.cgi?id=262951
          style={{ willChange: "z-index" }}
          onSubmit={(e) => {
            e.preventDefault();
            if (!canSave) return;
            onSaveAndContinue();
          }}
          data-cy="ManualStockTakingItemForm"
        >
          {isNew ? (
            <div className="text-right">
              <IconButton
                variant="tertiary"
                rounded="full"
                size="large"
                icon={CrossIcon}
                onClick={onCancel}
                data-cy="ManualStockTakingItemForm-close"
              />
            </div>
          ) : (
            <div className="flex justify-between">
              <IconButton
                variant="tertiary"
                rounded="full"
                size="large"
                icon={ArrowLeftIcon}
                onClick={onCancel}
                data-cy="ManualStockTakingItemForm-back"
              />
              <IconButton
                variant="tertiary"
                rounded="full"
                size="large"
                icon={TrashIcon}
                onClick={onRemove}
                data-cy="ManualStockTakingItemForm-remove"
              />
            </div>
          )}

          <div className="mb-6 text-center">
            <ItemImage
              // TODO - Default error image?
              assetUrl={item.imagePath ?? ""}
              alt={item.itemId}
              size="large"
            />
          </div>
          <Typography component="h3" variant="body" className="mb-2">
            <span>{t("stockTaking.articleNumberShort")} </span>
            <span data-cy="ManualStockTakingItemForm-itemId">
              {item.itemId}
            </span>
          </Typography>
          <Typography variant="body-sm" color="gray" className="mb-4">
            <div>{item.description1}</div>
            {item.description2 && <div>{item.description2}</div>}
          </Typography>
          <NumberInput
            autoNumericRef={focusQuantityCallback}
            value={currentStockTakingQuantity}
            onChange={(value) => setCurrentStockTakingQuantity(value)}
            onBlur={() => {
              if (currentStockTakingQuantity === undefined) {
                setCurrentStockTakingQuantity(0);
              }
            }}
            min={0}
            step={item.quantityStep}
            placeholder={`0 ${item.unit}`}
            displaySuffix={` ${squareCubedToUnicode(item.unit)}`}
            className="mb-4"
            data-cy="ManualStockTakingItemForm-quantity"
            locale={i18n.language}
          />
          <PurchaseHold stockId={warehouseId} itemId={item.itemId} />
          <Typography variant="body-sm" color="gray" className="mb-4">
            {lastStockTakeDate && (
              <LastStockTakeDate
                lastStockTakeDate={lastStockTakeDate}
                data-cy="ManualStockTakingItemForm-lastStockTake"
              />
            )}
            <div>
              <ItemLocation warehouseId={warehouseId} itemId={item.itemId} />
            </div>
          </Typography>
          {userHasStockReplenishmentStatusFeatureFlag && (
            <StockReplenishmentStatus
              itemId={item.itemId}
              unit={item.unit}
              className="mb-4"
            />
          )}
          {showDiff &&
            stockTakingQuantity !== undefined &&
            stockTakingQuantity !== item.expectedQuantityWhenCounted && (
              <Alert
                severity="attention"
                icon={InfoIcon}
                className="mb-6"
                data-cy="ManualStockTakingItemForm-diffAlert"
              >
                <Typography variant="body-sm">
                  {t("stockTaking.inStockMismatch", {
                    stockTakingQuantity,
                    stockBalance: item.expectedQuantityWhenCounted,
                    itemUnit: item.unit,
                  })}
                </Typography>
              </Alert>
            )}
          {showComment && (
            <>
              <Typography variant="body-sm" className="mb-1">
                {t("stockTaking.comment")}
              </Typography>
              <TextField
                className="mb-6 h-24 shrink-0"
                multiline
                placeholder={t("stockTaking.typeHere")}
                value={updatedComment}
                onChange={(e) => setUpdatedComment(e.target.value)}
                data-cy="ManualStockTakingItemForm-comment"
                error={
                  isTooLong
                    ? t("stockTaking.commentIsTooLong", {
                        maxLength,
                        length: formattedUpdatedComment?.length ?? 0,
                      })
                    : undefined
                }
              />
            </>
          )}
          <div className="grow" />
          <div className="flex flex-row gap-4">
            <Button
              variant="primary"
              className="flex-1"
              size="large"
              type="button"
              onClick={handleSave()}
              disabled={!canSave}
              data-cy="ManualStockTakingItemForm-save"
            >
              {t("save")}
            </Button>
          </div>
        </form>
      </AppPage>
    );
  };

export default ManualStockTakingItemForm;

import { useTranslation } from "@ahlsell-group/app-localization";
import { ProgressDots } from "@ahlsell-group/app-ui/ProgressDots";
import { SlideUpModal } from "@ahlsell-group/app-ui/SlideUpModal";
import { ValidatedItem } from "@ahlsell-group/store20-stock-taking-service";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Button } from "@ahlsell-group/ui-kit/inputs";
import { Center } from "@ahlsell-group/ui-kit/layout";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import ErrorModal from "../../error/components/ErrorModal";
import RouteLink from "../../routing/components/RouteLink";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import {
  selectManualStockTakingStatus,
  selectReviewState,
} from "../manualStockTakingSelectors";
import {
  dismissStockTakeReview,
  reviewStockTake,
  submitStockTakesRequested,
} from "../manualStockTakingSlice";

import ManualStockTakingLoadingModal from "./ManualStockTakingLoadingModal";
import { StockTakeCostDiff } from "./StockTakeCostDiff";
import { StockTakeQuantity } from "./StockTakeQuantity";

const ItemName: React.FC<{ item: ValidatedItem }> = function ({ item }) {
  return (
    <div className="overflow-hidden text-ellipsis whitespace-nowrap">
      {item.description1} {item.description2} {item.name}
    </div>
  );
};

export const ManualStockTakingReviewModal: React.FC = function () {
  const state = useSelector(selectReviewState);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = useSelector(selectManualStockTakingStatus);
  const { t } = useTranslation("common");

  useEffect(() => {
    if (!state) {
      dispatch(reviewStockTake());
    }
  }, [dispatch, state]);

  if (!state) {
    return null;
  }

  if (state.type === "error") {
    return (
      <ErrorModal
        category="stockTakeReview"
        error={state.error}
        onClose={() => navigate.back()}
        data-cy="ManualStockTakingReviewModal-error"
        data-error-reason={state.error.reason}
      />
    );
  }

  if (status === "submittingStockTakes") {
    return (
      <ManualStockTakingLoadingModal
        headerText={t("stockTaking.submittingStockTakeHeader")}
        detailText={t("stockTaking.submittingStockTakeDetail")}
        onClose={() => navigate.back()}
      />
    );
  }

  const data = state.type === "ok" ? state.data : undefined;

  const negativeDiff =
    data?.reduce(
      (sum, x) =>
        x.totalWeightedAverageCostDiff !== undefined &&
        x.totalWeightedAverageCostDiff < 0
          ? sum + x.totalWeightedAverageCostDiff
          : sum,
      0
    ) || undefined;

  const positiveDiff =
    data?.reduce(
      (sum, x) =>
        x.totalWeightedAverageCostDiff !== undefined &&
        x.totalWeightedAverageCostDiff > 0
          ? sum + x.totalWeightedAverageCostDiff
          : sum,
      0
    ) || undefined;

  const handleClose = () => {
    navigate.back();
  };

  const dismissReview = () => dispatch(dismissStockTakeReview());

  return (
    <SlideUpModal
      header={t("stockTakingReview.overview")}
      onClose={handleClose}
      data-cy="ManualStockTakingReviewModal"
      fullSize
    >
      {state.type === "loading" && (
        <Center>
          <ProgressDots />
        </Center>
      )}
      {data && (
        <ul className="grow flex flex-col gap-1 px-2 pb-4 text-theme-secondary-gray-700 text-body-xs">
          {data?.map((item) => {
            const {
              itemId,
              unit,
              stockTakingQuantity,
              expectedQuantity,
              comment,
              currency,
              quantityDiff,
              totalWeightedAverageCostDiff,
            } = item;

            if (expectedQuantity === stockTakingQuantity) {
              return (
                <li key={itemId}>
                  <RouteLink
                    variant="raw"
                    route={routes.stockTaking.manual.item}
                    routeParams={{
                      itemIdOrBarcode: itemId,
                      acceptQuantity: stockTakingQuantity,
                    }}
                    onClick={dismissReview}
                    className="flex flex-col px-2 pb-1"
                    data-cy={`ManualStockTakingReviewModal-link-${itemId}`}
                  >
                    <div className="flex">
                      <div className="basis-0 grow min-w-0">
                        <ItemName item={item} />
                        <div>
                          {t("stockTakingReview.itemIdShort")} {itemId}
                        </div>
                      </div>
                      <StockTakeQuantity
                        number={expectedQuantity}
                        unit={unit}
                      />
                    </div>
                    {comment && (
                      <div
                        data-cy="ManualStockTakingReviewModal-comment"
                        className="whitespace-pre-wrap"
                      >
                        {t("stockTakingReview.commentColon")} {comment}
                      </div>
                    )}
                  </RouteLink>
                </li>
              );
            }

            return (
              <li key={itemId}>
                <RouteLink
                  variant="raw"
                  route={routes.stockTaking.manual.item}
                  routeParams={{
                    itemIdOrBarcode: itemId,
                    acceptQuantity: stockTakingQuantity,
                  }}
                  onClick={dismissReview}
                  className="p-2 bg-theme-secondary-bg-100 flex flex-col gap-1.5"
                  data-cy={`ManualStockTakingReviewModal-link-${itemId}`}
                >
                  <div className="flex gap-4">
                    <div className="basis-0 grow min-w-0">
                      <ItemName item={item} />
                      <div>
                        <b>{t("stockTakingReview.itemIdShort")}</b> {itemId}
                      </div>
                    </div>
                    <StockTakeQuantity
                      number={stockTakingQuantity}
                      unit={unit}
                      warn
                    />
                  </div>
                  {comment && (
                    <div
                      data-cy="ManualStockTakingReviewModal-comment"
                      className="whitespace-pre-wrap"
                    >
                      <b>{t("stockTakingReview.commentColon")}</b> {comment}
                    </div>
                  )}
                  <div className="flex gap-4">
                    <b className="basis-0 grow">
                      {t("stockTakingReview.diff")}
                    </b>
                    <StockTakeCostDiff
                      totalWeightedAverageCostDiff={
                        totalWeightedAverageCostDiff
                      }
                      currency={currency}
                      diff
                      warn
                      data-cy="ManualStockTakingReviewModal-totalWeightedAverageCostDiff"
                    />
                    <StockTakeQuantity
                      number={quantityDiff}
                      unit={unit}
                      diff
                      warn
                      data-cy="ManualStockTakingReviewModal-quantityDiff"
                    />
                  </div>
                </RouteLink>
              </li>
            );
          })}
        </ul>
      )}
      {data && (
        <div className="bg-theme-primary-ultra-light pt-4 px-4 pb-6">
          <Typography variant="body-sm" className="mb-4">
            <div className="flex">
              <span className="basis-0 grow">
                {t("stockTakingReview.stockTakenItems")}
              </span>
              <StockTakeQuantity
                number={data.length}
                unit={t("stockTakingReview.numberOfItemsUnit")}
              />
            </div>
            {negativeDiff !== undefined && negativeDiff !== 0 && (
              <div className="flex">
                <span className="basis-0 grow">
                  {t("stockTakingReview.diff")}
                </span>
                <StockTakeCostDiff
                  totalWeightedAverageCostDiff={negativeDiff}
                  currency={data[0].currency}
                  diff
                  warn
                  data-cy="ManualStockTakingReviewModal-totalNegativeCostDiff"
                />
              </div>
            )}
            {positiveDiff !== undefined && positiveDiff !== 0 && (
              <div className="flex">
                <span className="basis-0 grow">
                  {t("stockTakingReview.diff")}
                </span>
                <StockTakeCostDiff
                  totalWeightedAverageCostDiff={positiveDiff}
                  currency={data[0].currency}
                  diff
                  warn
                  data-cy="ManualStockTakingReviewModal-totalPositiveCostDiff"
                />
              </div>
            )}
          </Typography>
          <div className="flex gap-4 mb-4">
            <Button
              variant="tertiary"
              className="grow"
              onClick={handleClose}
              data-cy="ManualStockTakingReviewModal-back"
            >
              {t("back")}
            </Button>
            <Button
              variant="primary"
              className="grow"
              onClick={() => {
                dispatch(
                  submitStockTakesRequested({
                    enableAutomaticCompletion: true,
                  })
                );
              }}
              data-cy="ManualStockTakingReviewModal-confirm"
            >
              {t("confirm")}
            </Button>
          </div>
          <Typography variant="body-xs" color="gray">
            {t("stockTakingReview.completionExplanation")}
          </Typography>
        </div>
      )}
    </SlideUpModal>
  );
};

import { useTranslation } from "@ahlsell-group/app-localization";
import { SearchPage } from "@ahlsell-group/app-ui/SearchPage";
import {
  InputMode,
  SearchPageKeyboardSelector,
} from "@ahlsell-group/app-ui/SearchPageKeyboardSelector";
import { ScannerBarcodeIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { SearchField } from "@ahlsell-group/ui-kit/inputs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UAParser from "ua-parser-js";

import useServiceContainer from "../../../app/components/ServiceContainerContext";
import CameraAccessAlert from "../../barcode-scanner/components/CameraAccessAlert";
import { CameraError } from "../../barcode-scanner/types";
import ErrorPageSection from "../../error/components/ErrorPageSection";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import {
  selectItemSearchError,
  selectItemSearchResult,
} from "../itemSearchSelectors";
import { itemSearchExited, itemSearchQueryEntered } from "../itemSearchSlice";
import { SearchMode } from "../types";
import { useItemSearchSuggestions } from "../useItemSearchSuggestions";

export interface ItemSearchInnerProps {
  searchMode: SearchMode;
  onSelect: (itemId: string) => void;
  cameraError?: CameraError;
}

const ItemSearchInner: React.FC<ItemSearchInnerProps> = function ({
  searchMode,
  onSelect,
  cameraError,
}) {
  const [inputMode, setInputMode] = useState<InputMode>(
    searchMode === "id-only" ? "numeric" : "text"
  );

  const { logService } = useServiceContainer();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchFieldRef = useRef<HTMLInputElement>(null);
  const searchError = useSelector(selectItemSearchError);
  const handleSearch = useCallback(
    (searchTerm: string): void => {
      dispatch(itemSearchQueryEntered({ term: searchTerm, searchMode }));
    },
    [dispatch, searchMode]
  );

  const searchResult = useSelector(selectItemSearchResult);
  const searchSuggestions = useItemSearchSuggestions(searchResult, onSelect);

  const handleClose = useCallback(() => navigate.back(), [navigate]);

  const [searchTerm, setSearchTerm] = useState("");
  const showError = cameraError && !searchTerm;

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        handleClose();
      }
    };

    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [handleClose]);

  useEffect(() => {
    searchFieldRef.current?.focus();
    return () => {
      dispatch(itemSearchExited());
    };
  }, [dispatch]);

  const handleInputModeChange = (newInputMode: InputMode) => {
    setInputMode(newInputMode);

    if (searchFieldRef.current) {
      // In Firefox, the input mode must be set *before* focusing, so
      // the asynchronous `setInputMode` won't be sufficient. This is
      // not necessary for other browsers.
      // I'm unable to create a reduced test case for this issue.
      searchFieldRef.current.inputMode = newInputMode;

      const isIos =
        UAParser(navigator.userAgent).os.name?.toLowerCase() === "ios";

      if (isIos) {
        // On iOS, changing the input mode while the search field is focused
        // doesn't change the keyboard immediately. Instead, blur the field,
        // encouraging the user to focus it themselves.
        searchFieldRef.current.blur();
      } else {
        // On other browsers, keeping focus works as expected.
        searchFieldRef.current.focus();
      }
    }

    logService.log("info", {
      context: "react",
      namespace: "ItemSearch",
      component: "ItemSearchInner",
      logType: `ChangeKeyboard/${newInputMode}`,
    });
  };

  return (
    <SearchPage
      data-cy="ItemSearchInner"
      searchField={
        <SearchField
          ref={searchFieldRef}
          active
          placeholder={t(
            searchMode === "free-text"
              ? "itemSearch.searchItem"
              : "itemSearch.searchItemId"
          )}
          onSearch={handleSearch}
          onCancel={handleClose}
          searchOnChange
          value={searchTerm}
          submitButtonProps={{
            "data-cy": "ItemSearchInner-scan",
            type: "button",
            onClick: () => navigate(routes.home.scan),
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
          data-cy="item-search-input"
          inputMode={inputMode}
          submitIcon={ScannerBarcodeIcon}
        />
      }
      keyboardSelector={
        searchMode === "id-only" && (
          <SearchPageKeyboardSelector
            label=""
            inputMode={inputMode}
            onChange={handleInputModeChange}
          />
        )
      }
      alert={showError && <CameraAccessAlert error={cameraError} />}
      error={
        searchError && (
          <ErrorPageSection
            category="itemSearch"
            error={searchError}
            data-cy="ItemSearchError"
            data-error-reason={`${searchError.reason}`}
          />
        )
      }
      noResults={
        searchResult && (
          <>
            <h3 className="font-bold">
              {t("itemSearch.noResults", {
                searchQuery: searchResult.searchQuery,
              })}
            </h3>
            <div>
              {t(
                searchMode === "id-only"
                  ? "itemSearch.tryAgainItemId"
                  : "itemSearch.tryAgain"
              )}
            </div>
          </>
        )
      }
      searchSuggestionsHeader={t("itemSearch.items")}
      searchSuggestions={searchSuggestions}
    />
  );
};

export default ItemSearchInner;

import i18n from "@ahlsell-group/app-localization";

export const getDateFromUnixMilliseconds = (unixMs: number) => {
  const now = new Date();
  const sameYear: boolean =
    now.getFullYear() === new Date(unixMs).getFullYear();

  return new Intl.DateTimeFormat(i18n.language, {
    day: "numeric",
    month: "long",
    year: sameYear ? undefined : "numeric",
  }).format(new Date(unixMs));
};
